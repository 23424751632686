<template>
  <div class="main" >
    <heads></heads>
    <ztflhbanners></ztflhbanners>
    <div class="content">
      <div class="cons">
        <div class="con-title">中途分类号</div>
        <!-- <div class="con-nav">
          <div class="con-nav-link" v-for="item in 20" :key="item">
            <span class="con-nav-linkxg" v-if="item!=1">/</span>
            <router-link to="/nav">中途分类号</router-link>
          </div>
        </div> -->
        <div class="con-ul">
          <div class="con-list" v-for="(item,key) in alllist" :key="key" @click="clickxq(item.Id)">
            <div class="con-list-title">{{item.zcode}}</div>
            <div class="con-list-text" :title="item.zname">{{item.zname}}</div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 列表
let alllist=reactive([])

// 获取列表
const getlist=()=>{
  get("https://xk-api.yuanpingjia.cn/api/Ztflh/GetTopFlh").then((res) => {
    // console.log(res);
    alllist.push(...res.data.list)
  });
}
getlist()

// 查看下一级
const clickxq=id=>{
  router.push({
    path: "/flh/list",
    query: {
      id:id
    },
  });
}

</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.con-nav-link a:link,.con-nav-link a:visited{
  text-decoration: none;
}
.con-nav-link a:hover{
  color: #409EFF;
}
.con-nav:after{
  content: '';
  display: table;
  clear: both;
}
.con-nav-link{
  float: left;
  margin: 0 10px 0 0;
}
.con-nav-linkxg{
  padding: 0 10px 0 0;
}

.con-title{
  padding: 0 14px 0 14px;
  font-size: 24px;
  font-weight: bold;
}
.con-nav{
  margin: 0 14px;
  text-align: justify;
  background: #E9ECEF;
  padding:15px 15px;
  border-radius: 4px;
  line-height: 24px;
}


.con-ul{
  margin: 20px 0 0 0;
}
.con-ul:after{
  content: '';
  display: table;
  clear: both;
}
.con-list{
  width: 350px;
  height: 80px;
  float: left;
  margin: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 10px;
  cursor: pointer;
}
.con-list:hover{
  background: #409EFF;
  color: #fff;
}
.con-list-title{
  text-align: center;
  padding: 15px 0 5px 0;
}
.con-list-text{
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
